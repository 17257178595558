import React, {
  forwardRef,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { IconProps, useApiProps } from "../icon";
// import "../style/css";

const BrowseIcon = forwardRef<SVGSVGElement, IconProps>(
  (props: IconProps, ref: LegacyRef<SVGSVGElement>) => {
    const {
      colors = [],
      className,
      id = "",
      hover = "",
      size,
      disabled = false,
      style,
      color,
      onMouseEnter,
      onMouseLeave,
      ...resultProps
    } = props;
    //@ts-ignore
    const { sizeStyle, cls } = useApiProps({
      className,
      initColor: ["currentColor"],
      initName: "browse",
      colors,
      id,
      hover,
      size,
      style,
      disabled,
      color,
    });
    // 默认的color 列表
    const [colorList, setColorList] = useState(["currentColor"]);
    const [colorsProps, setColorsProps] = useState(["currentColor"]);
    const [defaultColor, _] = useState(["currentColor"]);

    useEffect(() => {
      if (typeof colors === "string" && "browse".indexOf("-colored") === -1) {
        const col = JSON.parse(JSON.stringify(["currentColor"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = colors;
            }
            return item;
          });
        } else {
          _col[0] = colors;
        }
        setColorList(_col);
        setColorsProps(_col);
      }

      // 如果colors 是数组
      if (colors instanceof Array && "browse".indexOf("-colored") === -1) {
        // 获取数组中的对象
        const colorList1 = JSON.parse(JSON.stringify(["currentColor"]));
        colors.forEach((col) => {
          // 获取到修改的元素
          const findColorIndex = colorList1.findIndex(
            (v) => v === col.targetColor
          );
          if (findColorIndex >= 0) {
            colorList1[findColorIndex] = col.reviseColor;
          }
        });
        setColorList([...colorList1]);
        setColorsProps([...colorList1]);
      }

      if (
        color &&
        typeof color === "string" &&
        "browse".indexOf("-colored") === -1
      ) {
        const col = JSON.parse(JSON.stringify(["currentColor"]));
        const find = col.find((item) => item === "currentColor");
        let _col = col;
        if (find) {
          _col = col.map((item) => {
            if (item === "currentColor") {
              item = color;
            }
            return item;
          });
        } else {
          _col[0] = color;
        }
        setColorList(_col);
        setColorsProps(_col);
      }
    }, [JSON.stringify(colors), color, defaultColor]);

    const handleMouseEnter = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseEnter && onMouseEnter(event);
        if (!hover || hover.length === 0) return;
        if (colors instanceof Array && "browse".indexOf("-colored") === -1) {
          if (hover instanceof Array && "browse".indexOf("-colored") === -1) {
            // 获取数组中的对象
            const colorList1 = JSON.parse(JSON.stringify(colorList));
            hover.forEach((col) => {
              // 获取到修改的元素
              const findColorIndex = colorList1.findIndex(
                (v) => v === col.targetColor
              );
              if (findColorIndex >= 0) {
                colorList1[findColorIndex] = col.reviseColor;
              }
            });
            setColorList([...colorList1]);
          }
        }

        if (typeof colors === "string" && "browse".indexOf("-colored") === -1) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === colors);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === colors) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          color &&
          typeof color === "string" &&
          "browse".indexOf("-colored") === -1
        ) {
          if (typeof hover === "string") {
            const col = JSON.parse(JSON.stringify(colorList));
            const find = col.find((item) => item === color);
            let _col = col;
            if (find) {
              _col = col.map((item) => {
                if (item === color) {
                  item = hover;
                }
                return item;
              });
            } else {
              _col[0] = hover;
            }
            setColorList(_col);
          }
        }

        if (
          !color &&
          colors.length === 0 &&
          typeof hover === "string" &&
          "browse".indexOf("-colored") === -1
        ) {
          const col = JSON.parse(JSON.stringify(colorList));
          const find = col.find((item) => item === "currentColor");
          let _col = col;
          if (find) {
            _col = col.map((item) => {
              if (item === "currentColor") {
                item = hover;
              }
              return item;
            });
          } else {
            _col[0] = hover;
          }
          setColorList(_col);
        }

        if (
          !color &&
          colors.length === 0 &&
          hover instanceof Array &&
          "browse".indexOf("-colored") === -1
        ) {
          // 获取数组中的对象
          const colorList1 = JSON.parse(JSON.stringify(colorList));
          hover.forEach((col) => {
            // 获取到修改的元素
            const findColorIndex = colorList1.findIndex(
              (v) => v === col.targetColor
            );
            if (findColorIndex >= 0) {
              colorList1[findColorIndex] = col.reviseColor;
            }
          });
          setColorList([...colorList1]);
        }
      },
      [colorList, defaultColor, colors, color, hover]
    );

    const handleMouseLeave = useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        onMouseLeave && onMouseLeave(event);
        if (!hover || hover.length === 0) return;
        let _defaultColor = JSON.parse(JSON.stringify(defaultColor));
        if (colorsProps) {
          _defaultColor = JSON.parse(JSON.stringify(colorsProps));
        }
        setColorList(_defaultColor);
      },
      [colorsProps, defaultColor, hover]
    );

    return <svg id="browse"  style={sizeStyle}  className={cls}  ref={ref}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...resultProps}  xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g fill="none"><path d="M7.99984 4.66663C6.15889 4.66663 4.6665 6.15901 4.6665 7.99996C4.6665 9.84091 6.15889 11.3333 7.99984 11.3333C9.84079 11.3333 11.3332 9.84091 11.3332 7.99996C11.3332 6.15901 9.84079 4.66663 7.99984 4.66663ZM5.99984 7.99996C5.99984 6.89539 6.89527 5.99996 7.99984 5.99996C9.10441 5.99996 9.99984 6.89539 9.99984 7.99996C9.99984 9.10453 9.10441 9.99996 7.99984 9.99996C6.89527 9.99996 5.99984 9.10453 5.99984 7.99996Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/><path d="M7.99988 2.33337C4.41574 2.33337 1.36168 4.59576 0.184672 7.76814L0.0986328 8.00004L0.184672 8.23194C1.36168 11.4043 4.41574 13.6667 7.99988 13.6667C11.584 13.6667 14.6381 11.4043 15.8151 8.23194L15.9011 8.00004L15.8151 7.76814C14.6381 4.59576 11.584 2.33337 7.99988 2.33337ZM7.99988 12.3334C5.0791 12.3334 2.57461 10.5442 1.52554 8.00004C2.57461 5.45587 5.0791 3.66671 7.99988 3.66671C10.9207 3.66671 13.4251 5.45587 14.4742 8.00004C13.4251 10.5442 10.9207 12.3334 7.99988 12.3334Z" fill={colorList[0]} fillRule="evenodd" clipRule="evenodd" fillOpacity="0.9"/></g></svg>;
  }
);

BrowseIcon.displayName = "BrowseIcon";

export default BrowseIcon;
