/*
 * @Author: elizhai elizhai@tencent.com
 * @Date: 2022-08-11 12:45:14
 * @LastEditors: elizhai elizhai@tencent.com
 * @LastEditTime: 2022-08-11 12:46:19
 * @FilePath: /ruyi-icons/packages/react/src/util/use-config.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useContext } from 'react';
import { IconConfigContext } from '../configProvider';

export default () => useContext(IconConfigContext);
