import { CSSProperties } from "react";
import useCommonClassName from "./use-common-classname";

/**
 * 表示组件支持通过 className 和 style 进行样式定制
 */
export interface StyledProps {
  /**
   * 组件自定义类名
   */
  className?: string;

  /**
   * 组件自定义样式
   */
  style?: CSSProperties;
}

export default function useSizeProps(size?: string | number): StyledProps {
  const COMMON_SIZE_CLASS_NAMES = useCommonClassName().SIZE;
  if (typeof size === "undefined") {
    return {};
  }

  if (!(size in COMMON_SIZE_CLASS_NAMES)) {
    return {
      className: '',
      style: { width: size, height: size },
    };
  }

  return {
    className: COMMON_SIZE_CLASS_NAMES[size],
    style: {},
  };
}
